@font-face {
  font-family: kelson;
  font-style: normal;
  src: url(../fonts/KelsonSansBG-Normal.woff) format("woff"),
    url(../fonts//KelsonSansBG-Normal.woff2) format("woff2");
}

@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);

h3,
h2,
h5 {
  font-family: kelson;
}

p,
a {
  font-family: Roboto;
}

.container {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}

hr {
  height: 12px !important;
  border-top: 0.07rem solid !important;
  border-color: lightgrey !important;
}

.lineBottom {
  border-color: #333366 !important;
}
